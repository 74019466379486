<template>
  <v-container  class="text-center ">
        <v-row align="center" justify="center" class="fill-height my-5 py-5">
          <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h2 class="brandon mb-3">{{$t('pages.campaign.text')}}</h2>
                    <h1 @click="copyText($route.params.code)" class="primary--text display-3">{{$route.params.code}}</h1>
                  </v-col>
                  <v-col cols="12">
                    <v-card max-width="130px" class="mx-auto rounded-card" :elevation="4" target="_blank" :href="deeplink">
                     <v-img class="mx-auto" :src="require('@/assets/images/app-logo.png')"></v-img>
                    </v-card>
                    <h2 class="brandon mb-3">{{$t('pages.home.download-app')}}</h2>
                  </v-col>  
                  <v-col cols="6" >
                    <a :href="download.android" target="_blank">
                      <v-img class="mx-auto logo" :src="require('@/assets/images/google-store.svg')"></v-img>
                    </a>
                  </v-col>
                  <v-col cols="6">
                    <a :href="download.ios" target="_blank">
                      <v-img class="mx-auto logo" :src="require('@/assets/images/apple-store.svg')"></v-img>
                    </a>
                  </v-col>
                </v-row>
          </v-col>
        </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Home',
  data: () => ({
    title: process.env.VUE_APP_BASE_NAME,
    deeplink: process.env.VUE_APP_DEEP_LINK,
    download: {
      android: "https://play.google.com/store/apps/details?id=com.atono.venchi",
      ios: "https://apps.apple.com/it/app/venchi-cioccolato-gelato/id1473955521"
    }
  }),
  methods:{
    async copyText(mytext) {
        event.target.classList.add('text-bounce');
        var input = document.createElement('input');
               input.setAttribute('value', mytext);
               input.value = mytext;        
               document.body.appendChild(input);
               try {
                    input.select();    
                    input.click();     
                    input.focus();
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Testing code was copied ' + successful + ' ' + msg);
                    document.body.removeChild(input); 
                    this.$store.dispatch('snackBars/addSnack', {message: 'Testo: '+mytext+' <br> copiato correttamente', type: 'success'});
               } catch (err) {
                    console.log('Oops, unable to copy');
               }
               
    }
  }
};
</script>

<style scoped>
.logo{
  max-width: 190px;
  position: relative;
}
.v-card.rounded-card{
    border-radius:30px;
}
.text-bounce{
 -webkit-animation: jello-horizontal 0.9s both;
         animation: jello-horizontal 0.9s both;
}

.jello-horizontal {
 -webkit-animation: jello-horizontal 0.9s both;
         animation: jello-horizontal 0.9s both;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-6-15 16:29:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}


</style>
